<template>
    <div class="original-list">
        <!-- 没有作品 -->
        <div class="original-list-nothing" v-if="templateList.length == 0">
            <!-- 图片 -->
            <div class="original-list-nothing-bg"></div>
            <!-- 文字 -->
            <div class="original-list-nothing-tips">
                啊哦，你还没有上传作品呢，快去
                <router-link 
                    :to="{
                        name: 'originalEdit'
                    }"
                    class="nothing-tips-go">
                    上传！
                </router-link>
            </div>
        </div>
        <!-- 作品列表 -->
        <div class="original-list-box" v-else>
            <!-- 列表 -->
            <center-list
                :list-data="templateList"
                edit-router="originalEdit">
                <template #addTips>
                    上传模板
                </template>
                <template #listItem="{ item }">
                    <center-list-item
                        edit-router="originalEdit"
                        :item-pic="item.skin"
                        :item-id="item.id"
                        @handleDelete="handleDel(item.id)">
                        <template #info>
                            <div class="original-list-info">
                                <!-- 标题 -->
                                <div class="original-list-info-title">
                                    {{item.name}}
                                </div>
                                <!-- 副标题 -->
                                <div class="original-list-info-subtitle">
                                    已有{{item.useNum}}人使用
                                </div>
                                <!-- 价格 -->
                                <div class="original-list-info-price">
                                    价格：{{item.price}}元
                                </div>
                            </div>
                        </template>
                    </center-list-item>
                </template>
            </center-list>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations, mapActions } from "vuex";
    export default {
        computed: mapState({
            user_id: state => state.user.user_id,
            templateList: state => state.original.templateList
        }),
        components: {
            centerList: () => import("@/components/centerList"),
            centerListItem: () => import("@/components/centerListItem")
        },
        methods: {
            ...mapMutations([
                'setTemplateList'
            ]),
            ...mapActions([
                'getTemplateList',
                'handleDelTemplate'
            ]),
            handleDel(worksID){ // 删除
                const userID = this.user_id;
                this.handleDelTemplate({userID, worksID})
                    .then(res => {
                        const isSucc = res.type == 'success';
                        this.$message({
                            showClose: true,
                            message: res.msg,
                            type: isSucc? 'success': 'error'
                        })
                        isSucc && this.getTemplateList(userID);
                    })
                    .catch(err => {
                        this.$message({
                            showClose: true,
                            message: `删除接口异常${err}`,
                            type: 'error'
                        })
                    })
            }
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                vm.getTemplateList(vm.user_id);
            });
        },
        beforeRouteLeave (to, from, next) {
            this.setTemplateList({});
            next();
        }
    }
</script>

<style scoped>
/* 没有作品 */
.original-list-nothing{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 350px;
}
.original-list-nothing-bg{
    height: 160px;
    margin: 20px 0;
    background-image: url("../../assets/images/interface/freelance1.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.original-list-nothing-tips{
    text-align: center;
    color: #909399;
}
.nothing-tips-go {
    border-bottom: 1px solid #909399;
    color: #909399;
}
.nothing-tips-go:hover {
    border-color: #8d9afc;
    color: #8d9afc;
}
/* 信息 */
.original-list-info{
    display: flex;
    flex-direction: column;
    height: 100%;
}
.original-list-info-title{
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
}
.original-list-info-subtitle{
    flex: 1;
    color: #909399;
}
.original-list-info-price{
    align-self: flex-end;
    font-size: 16px;
    color: #e6a23c;
}
</style>